var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-8" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body form-card" }, [
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    class: _vm.errors.has("vi_title") ? "border-danger" : "",
                    attrs: {
                      placeholder: "Tên",
                      "data-vv-name": "vi_title",
                      "data-vv-as": "Tên",
                    },
                    model: {
                      value: _vm.form.vi_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "vi_title", $$v)
                      },
                      expression: "form.vi_title",
                    },
                  }),
                  _vm.errors.has("vi_title")
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("vi_title"))),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(1),
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Tên (en)" },
                    model: {
                      value: _vm.form.en_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "en_title", $$v)
                      },
                      expression: "form.en_title",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(2),
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Link" },
                    model: {
                      value: _vm.form.slug,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "slug", $$v)
                      },
                      expression: "form.slug",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(3),
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Sku" },
                    model: {
                      value: _vm.form.sku,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sku", $$v)
                      },
                      expression: "form.sku",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(4),
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min_value:0",
                        expression: "'required|min_value:0'",
                      },
                    ],
                    class: _vm.errors.has("price") ? "border-danger" : "",
                    attrs: {
                      type: "number",
                      placeholder: "Giá",
                      "data-vv-name": "price",
                      "data-vv-as": "Giá",
                    },
                    model: {
                      value: _vm.form.price,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "price", $$v)
                      },
                      expression: "form.price",
                    },
                  }),
                  _vm.errors.has("price")
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("price"))),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(5),
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min_value:0",
                        expression: "'required|min_value:0'",
                      },
                    ],
                    class: _vm.errors.has("inventory") ? "border-danger" : "",
                    attrs: {
                      type: "number",
                      placeholder: "Tồn kho",
                      "data-vv-name": "inventory",
                      "data-vv-as": "Tồn kho",
                    },
                    model: {
                      value: _vm.form.inventory,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "inventory", $$v)
                      },
                      expression: "form.inventory",
                    },
                  }),
                  _vm.errors.has("inventory")
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("inventory"))),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(6),
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Link size chart" },
                    model: {
                      value: _vm.form.link_size_chart,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "link_size_chart", $$v)
                      },
                      expression: "form.link_size_chart",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(7),
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    model: {
                      value: _vm.form.has_variant,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "has_variant", $$v)
                      },
                      expression: "form.has_variant",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _vm.form.has_variant
          ? _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body form-card" }, [
                _c("div", { staticClass: "form-group row" }, [
                  _vm._m(8),
                  _c(
                    "div",
                    { staticClass: "col-10" },
                    [
                      _vm._l(_vm.addon_option_1, function (tag) {
                        return _c(
                          "el-tag",
                          {
                            key: tag,
                            attrs: {
                              type: "primary",
                              closable: "",
                              "disable-transitions": false,
                            },
                            on: {
                              close: function ($event) {
                                return _vm.handleClose1(tag)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(tag) +
                                "\n              "
                            ),
                          ]
                        )
                      }),
                      _vm.inputVisible1
                        ? _c("el-input", {
                            ref: "saveTagInput1",
                            staticClass: "input-new-tag",
                            attrs: { size: "mini" },
                            on: { blur: _vm.handleInputConfirm1 },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.handleInputConfirm1.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            model: {
                              value: _vm.inputNewTag1,
                              callback: function ($$v) {
                                _vm.inputNewTag1 = $$v
                              },
                              expression: "inputNewTag1",
                            },
                          })
                        : _c(
                            "el-button",
                            {
                              staticClass: "button-new-tag",
                              attrs: { size: "small" },
                              on: { click: _vm.showInput1 },
                            },
                            [_vm._v("+ Thêm thuộc tính")]
                          ),
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _vm._m(9),
                  _c(
                    "div",
                    { staticClass: "col-10" },
                    [
                      _vm._l(_vm.addon_option_2, function (tag) {
                        return _c(
                          "el-tag",
                          {
                            key: tag,
                            attrs: {
                              type: "primary",
                              closable: "",
                              "disable-transitions": false,
                            },
                            on: {
                              close: function ($event) {
                                return _vm.handleClose2(tag)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(tag) +
                                "\n              "
                            ),
                          ]
                        )
                      }),
                      _vm.inputVisible2
                        ? _c("el-input", {
                            ref: "saveTagInput2",
                            staticClass: "input-new-tag",
                            attrs: { size: "mini" },
                            on: { blur: _vm.handleInputConfirm2 },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.handleInputConfirm2.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            model: {
                              value: _vm.inputNewTag2,
                              callback: function ($$v) {
                                _vm.inputNewTag2 = $$v
                              },
                              expression: "inputNewTag2",
                            },
                          })
                        : _c(
                            "el-button",
                            {
                              staticClass: "button-new-tag",
                              attrs: { size: "small" },
                              on: { click: _vm.showInput2 },
                            },
                            [_vm._v("+ Thêm thuộc tính")]
                          ),
                    ],
                    2
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "col-4" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body form-card" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "col-5" }, [_vm._v("Hiển thị")]),
                _c("el-switch", {
                  staticClass: "col-7",
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "col-5" }, [_vm._v("Sản phẩm")]),
                _c("el-switch", {
                  staticClass: "col-7",
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.form.is_product,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_product", $$v)
                    },
                    expression: "form.is_product",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "col-5" }, [_vm._v("Khắc tên")]),
                _c("el-switch", {
                  staticClass: "col-7",
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.form.engraved_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "engraved_name", $$v)
                    },
                    expression: "form.engraved_name",
                  },
                }),
              ],
              1
            ),
            _vm.form.engraved_name
              ? _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "col-5" }, [
                      _vm._v("Phí khắc tên"),
                    ]),
                    _c("el-input", {
                      staticClass: "col-7",
                      attrs: { placeholder: "0", type: "number" },
                      model: {
                        value: _vm.form.engraved_name_price,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "engraved_name_price", $$v)
                        },
                        expression: "form.engraved_name_price",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.form.engraved_name
              ? _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "col-5" }, [
                      _vm._v("Số ký tự tối đa"),
                    ]),
                    _c("el-input", {
                      staticClass: "col-7",
                      attrs: { placeholder: "0", type: "number" },
                      model: {
                        value: _vm.form.engraved_name_max_length,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "engraved_name_max_length", $$v)
                        },
                        expression: "form.engraved_name_max_length",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body form-card" },
            [
              _vm._m(10),
              _c("my-image", {
                model: {
                  value: _vm.form.image,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "image", $$v)
                  },
                  expression: "form.image",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _vm.form.has_variant
          ? _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body form-card" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c(
                        "el-table",
                        {
                          staticClass: "table table-bordered",
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.tableData },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "title",
                              label: "Tiêu đề",
                              width: "150",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "price", label: "Giá" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (props) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$util.formatMoney(
                                              props.row["price"]
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1304432927
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "inventory", label: "Tồn kho" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (props) {
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(props.row["inventory"])),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2141121412
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "priority",
                              label: "Độ ưu tiên",
                              width: "150",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "min-width": 120,
                              fixed: "right",
                              "class-name": "td-actions",
                              label: "Tác vụ",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "p-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "sm",
                                            icon: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.edit(
                                                props.$index,
                                                props.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "nc-icon nc-ruler-pencil",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "p-button",
                                        {
                                          attrs: {
                                            type: "danger",
                                            size: "sm",
                                            icon: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.remove(
                                                props.$index,
                                                props.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "nc-icon nc-simple-remove",
                                          }),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2411792756
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-2" },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Tiêu đề" },
                        model: {
                          value: _vm.variantDetail.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.variantDetail, "title", $$v)
                          },
                          expression: "variantDetail.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-2" },
                    [
                      _c("el-input", {
                        attrs: { type: "number", placeholder: "Giá" },
                        model: {
                          value: _vm.variantDetail.price,
                          callback: function ($$v) {
                            _vm.$set(_vm.variantDetail, "price", $$v)
                          },
                          expression: "variantDetail.price",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-2" },
                    [
                      _c("el-input", {
                        attrs: { type: "number", placeholder: "Tồn kho" },
                        model: {
                          value: _vm.variantDetail.inventory,
                          callback: function ($$v) {
                            _vm.$set(_vm.variantDetail, "inventory", $$v)
                          },
                          expression: "variantDetail.inventory",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-2" },
                    [
                      _c("el-input", {
                        attrs: { type: "number", placeholder: "Độ ưu tiên" },
                        model: {
                          value: _vm.variantDetail.priority,
                          callback: function ($$v) {
                            _vm.$set(_vm.variantDetail, "priority", $$v)
                          },
                          expression: "variantDetail.priority",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-2" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.saveVariant },
                        },
                        [
                          _vm._v(
                            "\n                Thêm phiên bản\n              "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body form-card" }, [
            _c(
              "div",
              { attrs: { ckass: "row" } },
              [
                _vm._m(11),
                _c(
                  "el-tabs",
                  {
                    model: {
                      value: _vm.activeTabDescription,
                      callback: function ($$v) {
                        _vm.activeTabDescription = $$v
                      },
                      expression: "activeTabDescription",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "Tiếng việt", name: "first" } },
                      [
                        _c("my-editor", {
                          attrs: {
                            apiKey:
                              "pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23",
                          },
                          model: {
                            value: _vm.form.vi_description,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "vi_description", $$v)
                            },
                            expression: "form.vi_description",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "Tiếng Anh", name: "second" } },
                      [
                        _c("my-editor", {
                          attrs: {
                            apiKey:
                              "pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23",
                          },
                          model: {
                            value: _vm.form.en_description,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "en_description", $$v)
                            },
                            expression: "form.en_description",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("br"),
            _c("br"),
            _c(
              "div",
              { attrs: { ckass: "row" } },
              [
                _vm._m(12),
                _c(
                  "el-tabs",
                  {
                    model: {
                      value: _vm.activeTabContent,
                      callback: function ($$v) {
                        _vm.activeTabContent = $$v
                      },
                      expression: "activeTabContent",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "Tiếng việt", name: "first" } },
                      [
                        _c("my-editor", {
                          attrs: {
                            apiKey:
                              "pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23",
                          },
                          model: {
                            value: _vm.form.vi_content,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "vi_content", $$v)
                            },
                            expression: "form.vi_content",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "Tiếng Anh", name: "second" } },
                      [
                        _c("my-editor", {
                          attrs: {
                            apiKey:
                              "pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23",
                          },
                          model: {
                            value: _vm.form.en_content,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "en_content", $$v)
                            },
                            expression: "form.en_content",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "container-fluid" }, [
        _c(
          "div",
          { staticClass: "page-action" },
          [
            _c("el-button", { on: { click: _vm.cancel } }, [
              _vm._v("Quay lại"),
            ]),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.save } },
              [_vm._v("Lưu lại")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", { staticClass: "required" }, [_vm._v("Tên")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", [_vm._v("Tên (en)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [_c("label", [_vm._v("Link")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [_c("label", [_vm._v("Sku")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", { staticClass: "required" }, [_vm._v("Giá")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", { staticClass: "required" }, [_vm._v("Tồn kho")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", [_vm._v("Link size chart")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", [_vm._v("Biến thể")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", [_vm._v("Thuộc tính 1")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", [_vm._v("Thuộc tính 2")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("label", [_vm._v("Hình ảnh")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("label", [_vm._v("Mô tả tiện ích")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("label", [_vm._v("Mô tả sản phẩm")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }