<template lang="html">
  <div>
    <div class="row">
      <div class="col-8">

        <div class="card">
          <div class="card-body form-card">
            <div class="form-group row">
              <div class="col-2">
                <label class="required">Tên</label>
              </div>
              <div class="col-10">
                <el-input
                  placeholder="Tên"
                  v-model="form.vi_title"
                  :class="errors.has('vi_title')?'border-danger':''"
                  v-validate="'required'"
                  data-vv-name="vi_title"
                  data-vv-as="Tên"
                ></el-input>
                <span class="text-danger" v-if="errors.has('vi_title')">{{ errors.first('vi_title') }}</span>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-2">
                <label>Tên (en)</label>
              </div>
              <div class="col-10">
                <el-input placeholder="Tên (en)" v-model="form.en_title"></el-input>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-2">
                <label>Link</label>
              </div>
              <div class="col-10">
                <el-input placeholder="Link" v-model="form.slug"></el-input>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-2">
                <label>Sku</label>
              </div>
              <div class="col-10">
                <el-input placeholder="Sku" v-model="form.sku"></el-input>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-2">
                <label class="required">Giá</label>
              </div>
              <div class="col-10">
                <el-input
                  type="number"
                  placeholder="Giá"
                  v-model="form.price"
                  :class="errors.has('price')?'border-danger':''"
                  v-validate="'required|min_value:0'"
                  data-vv-name="price"
                  data-vv-as="Giá"
                ></el-input>
                <span class="text-danger" v-if="errors.has('price')">{{ errors.first('price') }}</span>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-2">
                <label class="required">Tồn kho</label>
              </div>
              <div class="col-10">
                <el-input
                  type="number"
                  placeholder="Tồn kho"
                  v-model="form.inventory"
                  :class="errors.has('inventory')?'border-danger':''"
                  v-validate="'required|min_value:0'"
                  data-vv-name="inventory"
                  data-vv-as="Tồn kho"
                ></el-input>
                <span class="text-danger" v-if="errors.has('inventory')">{{ errors.first('inventory') }}</span>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-2">
                <label>Link size chart</label>
              </div>
              <div class="col-10">
                <el-input placeholder="Link size chart" v-model="form.link_size_chart"></el-input>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-2">
                <label>Biến thể</label>
              </div>
              <div class="col-10">
                <el-switch
                  v-model="form.has_variant"
                  :active-value="1"
                  :inactive-value="0">
                </el-switch>
              </div>
            </div>

          </div>
        </div>

        <div class="card" v-if="form.has_variant">
          <div class="card-body form-card">

            <div class="form-group row">
              <div class="col-2">
                <label>Thuộc tính 1</label>
              </div>
              <div class="col-10">
                <el-tag
                  :key="tag"
                  type="primary"
                  v-for="tag in addon_option_1"
                  closable
                  :disable-transitions="false"
                  @close="handleClose1(tag)">
                  {{tag}}
                </el-tag>
                <el-input
                  class="input-new-tag"
                  v-if="inputVisible1"
                  v-model="inputNewTag1"
                  ref="saveTagInput1"
                  size="mini"
                  @keyup.enter.native="handleInputConfirm1"
                  @blur="handleInputConfirm1"
                >
                </el-input>
                <el-button v-else class="button-new-tag" size="small" @click="showInput1">+ Thêm thuộc tính</el-button>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-2">
                <label>Thuộc tính 2</label>
              </div>
              <div class="col-10">
                <el-tag
                  :key="tag"
                  type="primary"
                  v-for="tag in addon_option_2"
                  closable
                  :disable-transitions="false"
                  @close="handleClose2(tag)">
                  {{tag}}
                </el-tag>
                <el-input
                  class="input-new-tag"
                  v-if="inputVisible2"
                  v-model="inputNewTag2"
                  ref="saveTagInput2"
                  size="mini"
                  @keyup.enter.native="handleInputConfirm2"
                  @blur="handleInputConfirm2"
                >
                </el-input>
                <el-button v-else class="button-new-tag" size="small" @click="showInput2">+ Thêm thuộc tính</el-button>
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="col-4">
        <div class="card">
          <div class="card-body form-card">

            <div class="form-group">
              <label class="col-5">Hiển thị</label>
              <el-switch
                class="col-7"
                v-model="form.status"
                :active-value="1"
                :inactive-value="0">
              </el-switch>
            </div>

            <div class="form-group">
              <label class="col-5">Sản phẩm</label>
              <el-switch
                class="col-7"
                v-model="form.is_product"
                :active-value="1"
                :inactive-value="0">
              </el-switch>
            </div>

            <div class="form-group">
              <label class="col-5">Khắc tên</label>
              <el-switch
                class="col-7"
                v-model="form.engraved_name"
                :active-value="1"
                :inactive-value="0">
              </el-switch>
            </div>

            <div v-if="form.engraved_name" class="form-group">
              <label class="col-5">Phí khắc tên</label>
              <el-input
                class="col-7"
                placeholder="0"
                type="number"
                v-model="form.engraved_name_price"
              ></el-input>
            </div>

            <div v-if="form.engraved_name" class="form-group">
              <label class="col-5">Số ký tự tối đa</label>
              <el-input
                class="col-7"
                placeholder="0"
                type="number"
                v-model="form.engraved_name_max_length"
              ></el-input>
            </div>

          </div>
        </div>

        <div class="card">
          <div class="card-body form-card">
            <div>
              <label>Hình ảnh</label>
            </div>
            <my-image v-model="form.image"></my-image>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card" v-if="form.has_variant">
          <div class="card-body form-card">
            <div class="row">
              <div class="col-12">
                <el-table
                  class="table table-bordered"
                  :data="tableData"
                  style="width: 100%">

                  <el-table-column
                    prop="title"
                    label="Tiêu đề"
                    width="150">
                  </el-table-column>

                  <el-table-column
                    prop="price"
                    label="Giá">
                    <template slot-scope="props">
                    <span>{{ $util.formatMoney(props.row['price']) }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="inventory"
                    label="Tồn kho">
                    <template slot-scope="props">
                    <span>{{ props.row['inventory'] }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="priority"
                    label="Độ ưu tiên"
                    width="150">
                  </el-table-column>

                  <el-table-column
                    :min-width="120"
                    fixed="right"
                    class-name="td-actions"
                    label="Tác vụ"
                  >
                    <template slot-scope="props">
                      <p-button type="primary"  size="sm" icon @click="edit(props.$index, props.row)">
                        <i class="nc-icon nc-ruler-pencil"></i>
                      </p-button>
                      <p-button type="danger" size="sm" icon @click="remove(props.$index, props.row)">
                        <i class="nc-icon nc-simple-remove"></i>
                      </p-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>

            <div class="row">
              <div class="col-2">
                <el-input placeholder="Tiêu đề" v-model="variantDetail.title"></el-input>
              </div>

              <div class="col-2">
                <el-input type="number" placeholder="Giá" v-model="variantDetail.price"></el-input>
              </div>

              <div class="col-2">
                <el-input type="number" placeholder="Tồn kho" v-model="variantDetail.inventory"></el-input>
              </div>

              <div class="col-2">
                <el-input type="number" placeholder="Độ ưu tiên" v-model="variantDetail.priority"></el-input>
              </div>

              <div class="col-2">
                <el-button type="primary" @click="saveVariant">
                  Thêm phiên bản
                </el-button>
              </div>

            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body form-card">
            <div ckass="row">
              <div><label>Mô tả tiện ích</label></div>
              <el-tabs v-model="activeTabDescription">
                <el-tab-pane label="Tiếng việt" name="first">
                  <my-editor
                    v-model="form.vi_description"
                    apiKey="pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23">
                  </my-editor>
                </el-tab-pane>
                <el-tab-pane label="Tiếng Anh" name="second">
                  <my-editor
                    v-model="form.en_description"
                    apiKey="pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23">
                  </my-editor>
                </el-tab-pane>
              </el-tabs>
            </div>
            <br>
            <br>
            <div ckass="row">
              <div><label>Mô tả sản phẩm</label></div>
              <el-tabs v-model="activeTabContent">
                <el-tab-pane label="Tiếng việt" name="first">
                  <my-editor
                    v-model="form.vi_content"
                    apiKey="pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23">
                  </my-editor>
                </el-tab-pane>
                <el-tab-pane label="Tiếng Anh" name="second">
                  <my-editor
                    v-model="form.en_content"
                    apiKey="pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23">
                  </my-editor>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="page-action">
          <el-button @click="cancel">Quay lại</el-button>
          <el-button type="primary" @click="save">Lưu lại</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { MessageBox, Message, Input, Upload, Table, Switch, TableColumn, Button, Tabs, TabPane, Tag } from 'element-ui';
  import MyImage from 'src/components/UIComponents/Image'
  import MyEditor from 'src/components/UIComponents/Editor'

  export default {
    components: {
      ElInput: Input,
      ElButton: Button,
      ElUpload: Upload,
      ElTable: Table,
      ElTableColumn: TableColumn,
      ElSwitch: Switch,
      ElTabs: Tabs,
      ElTabPane: TabPane,
      ElTag: Tag,
      MyEditor,
      MyImage,
      Message
    },

    data() {
      return {
        form: {
          price: 0
        },
        tableData: [],
        variantDetail: [],
        imageUrl: '',
        editRow: false,
        activeTabDescription: 'first',
        activeTabContent: 'first',

        addon_option_1: [],
        addon_option_2: [],
        inputVisible1: false,
        inputVisible2: false,
        inputNewTag1: '',
        inputNewTag2: ''
      }
    },

    mounted() {
      this.$store.dispatch('setPageTitle', 'Tạo tiện ích');
      this.$store.dispatch('setCurrentActions', [{
        label: 'Lưu lại',
        type: 'primary',
        icon: '',
        callback: this.save
      }])
    },

    methods: {

      handleClose1(tag) {
        this.addon_option_1.splice(this.addon_option_1.indexOf(tag), 1);
      },

      showInput1() {
        this.inputVisible1 = true;
        this.$nextTick(_ => {
          this.$refs.saveTagInput1.$refs.input.focus();
        });
      },

      handleInputConfirm1() {
        let inputNewTag1 = this.inputNewTag1;
        if (inputNewTag1 && !this.addon_option_1.includes(inputNewTag1)) {
          this.addon_option_1.push(inputNewTag1);
        }
        this.inputVisible1 = false;
        this.inputNewTag1 = '';
      },

      handleClose2(tag) {
        this.addon_option_2.splice(this.addon_option_2.indexOf(tag), 1);
      },

      showInput2() {
        this.inputVisible2 = true;
        this.$nextTick(_ => {
          this.$refs.saveTagInput2.$refs.input.focus();
        });
      },

      handleInputConfirm2() {
        let inputNewTag2 = this.inputNewTag2;
        if (inputNewTag2 && !this.addon_option_2.includes(inputNewTag2)) {
          this.addon_option_2.push(inputNewTag2);
        }
        this.inputVisible2 = false;
        this.inputNewTag2 = '';
      },

      cancel() {
        this.$router.push({name: 'AllAddons'});
      },

      edit(index, row){
        this.variantDetail = row;
      },

      remove(index, row){
        MessageBox.confirm('Bạn có chắc chắn xóa không?', 'Warning', {
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
          type: 'warning',
          center: true
        }).then(() => {
          this.tableData = this.tableData.filter((r) => {
            return r.id != row.id;
          });
        }).catch(() => {
        })
      },

      saveVariant(){
        if (!this.variantDetail.title) this.variantDetail.title = 'Default';
        if (!this.variantDetail.description) this.variantDetail.description = '';
        if (!this.variantDetail.price) this.variantDetail.price = 0;
        if (!this.variantDetail.priority) this.variantDetail.priority = 1;
        if (!this.variantDetail.inventory) this.variantDetail.inventory = 0;

        if (!this.variantDetail.id) {
          let id = Number(new Date()) + Math.floor((Math.random() * 10000) + 1);
          this.variantDetail.id = id;
          this.tableData.push(Object.assign({}, this.variantDetail));
        }

        this.variantDetail = [];
      },

      save() {
        let self = this;
        this.$validator.validateAll().then(function (result) {

          if (!result) {
            return Message({
              message: 'Vui lòng nhập đầy đủ thông tin',
              type: 'error'
            });
          }

          let data = self.form;
          data.option_1 = [...self.addon_option_1];
          data.option_2 = [...self.addon_option_2];

          let variant = self.tableData;
          data.variant = variant;

          self.$store.dispatch('storeAddon', data).then(res => {
            Message({message: 'Tạo mới thành công', type: 'success'});
            setTimeout(() => {
              self.$router.push('/addons/' + res.data.id);
            }, 1500);

          }, error => {
            Message({
              dangerouslyUseHTMLString: true,
              message: error,
              type: 'error'
            });
          });

        })
      },

      combinationVariants() {
        this.tableData = [];
        for (let option_1 of this.addon_option_1) {
          for (let option_2 of this.addon_option_2) {
            let id = Number(new Date()) + Math.floor((Math.random() * 10000) + 1);
            this.tableData.push({
              id: id,
              title: option_1 + '/' + option_2,
              price: this.form.price,
              priority: 1,
              inventory: 9999,
              option_1: option_1,
              option_2: option_2
            });
          }
        }
      }

    },

    watch: {
      addon_option_1(newVal) {
        this.combinationVariants();
      },

      addon_option_2(newVal) {
        this.combinationVariants();
      },

      'form.vi_title': function(newVal) {
        if (newVal) {
          this.form.slug = this.$util.createHandle(newVal);
        }
      }

    },

    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
